import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Table, Container, Row, Col, Modal, Button } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Loader from "./Loader";
import "../style/responsive.css";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { Select } from "antd";
import io from "socket.io-client";
import axios, { instance } from "../config";
import { toast } from "react-toastify";
import "../style/order.css";
import Recorder from "../Componentsnew/Recorder/Recorder";
import Webcamera from "../Componentsnew/webcam/Webcamera";
import useAuth from "../hooks/useAuth";
import { Link } from "react-router-dom";
import constant from "../constant";
import { IoLocationSharp } from "react-icons/io5";
import { Carousel } from "react-bootstrap";
import WebCamMulti from "../Componentsnew/webcam/WebCamMulti";
import { useNavigate } from "react-router-dom";
import { VscEye } from "react-icons/vsc";
import { IoMdEye } from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";
import { BsFilterSquareFill } from "react-icons/bs";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const { washrzserver } = constant;

const { Option } = Select;
// const socket = io(washrzserver); // Update with your backend URL

const CustomerDetails = () => {
  const navigate = useNavigate();
  const [customer, setCustomer] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [currentOrderId, setCurrentOrderId] = useState(null);
  const [newStatus, setNewStatus] = useState("");
  const [voiceFile, setVoiceFile] = useState(null);
  const [CapturedImages, setCapturedImages] = useState([]);
  const [show, setShow] = useState(false);
  const [isLoadings, setIsLoadings] = useState(false);
  const [showMedia, setShowMedia] = useState(false);
  const [showMultiMedia, setShowMultiMedia] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [photoUrl, setPhotoUrl] = useState(null);
  const [voiceUrl, setVoiceUrl] = useState(null);
  const [photoUrls, setPhotoUrls] = useState([]);
  const [hoveredButton, setHoveredButton] = useState(null);
  const [tooltipText, setTooltipText] = useState("");
  const [showFilterShowCalendar, setFilterShowCalendar] = useState(false);
  const { auth } = useAuth();
  const pageSize = 15;
  const axiosPrivate = useAxiosPrivate();

  // useEffect(() => {
  //   const getCustomer = async () => {
  //     try {
  //       const userEmail = auth?.email;
  //       const customer = await axiosPrivate.get(
  //         `/getOrders?limit=${pageSize}&page=${pageNumber}&email=${userEmail}`
  //       );
  //       setPageCount(Math.ceil(customer?.data?.total / pageSize));
  //       setCustomer(customer.data.orders);
  //       setIsLoading(false);
  //     } catch (error) {
  //       setIsLoading(false);
  //       console.log(error);
  //     }
  //   };
  //   getCustomer();
  // }, [pageNumber]);

  const getOrders = async (date = selectedDate, page = pageNumber) => {
    setIsLoading(true);
    // const formattedDate = selectedDate.toISOString().split("T")[0]; // Format date as YYYY-MM-DD
    const formattedDate = moment(date).format("YYYY-MM-DD");
    try {
      const userEmail = auth?.email;
      const response = await axiosPrivate.get(
        `/getOrders?limit=${pageSize}&page=${page}&email=${userEmail}&date=${formattedDate}`
      );
      setPageCount(Math.ceil(response?.data?.total / pageSize));
      setCustomer(response.data.orders);
      setIsLoading(false);
      setFilterShowCalendar(false); // Close calendar after fetching data
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching orders:", error);
    }
  };

  useEffect(() => {
    getOrders(selectedDate, pageNumber); // Fetch data on initial load
  }, [selectedDate, pageNumber]); // Update when page number changes

  const handleDateChange = (date) => {
    setSelectedDate(date); // Update selected date state
    setPageNumber(1);
    // getOrders(date); // Fetch data for selected date
  };

  // useEffect(() => {
  //   socket.on("orderStatusUpdated", (updatedOrder) => {
  //     setCustomer((prevOrders) =>
  //       prevOrders.map((order) =>
  //         order._id === updatedOrder._id ? updatedOrder : order
  //       )
  //     );
  //   });

  //   return () => {
  //     socket.off("orderStatusUpdated");
  //   };
  // }, []);

  const handleChange = async (id, value) => {
    setCurrentOrderId(id);
    setNewStatus(value);
    setShow(true); // Open confirmation modal
  };

  const handleClose = async () => {
    setShow(false); // Close confirmation modal
    setIsLoadings(true);

    try {
      if (newStatus === "processing") {
        setShowModal(true); // Open file upload modal
      } else {
        await axios.patch(`/auth/updateOrderStatus/${currentOrderId}`, {
          status: newStatus,
        });
        // socket.emit("updateOrderStatus", {
        //   id: currentOrderId,
        //   status: newStatus,
        // });

        // Trigger the WhatsApp template if status is 'ready for delivery'
        if (newStatus === "ready for delivery") {
          sendWhatsAppTemplate(currentOrderId);
          getOrders();
        }
        if (newStatus === "delivered") {
          sendWhatsAppTemplateDelivered(currentOrderId);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Error updating order status.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleRecordingComplete = (audioBlob) => {
    setVoiceFile(audioBlob); // Save the recorded audio as a file
  };
  ///////////////////////////////////
  const handleUpload = async () => {
    if (!CapturedImages || CapturedImages.length === 0) {
      toast.error("Please capture or upload at least one image.");
      navigateBasedOnRole();
      return;
    }

    const formData = new FormData();
    // Append all images with the field name `image`
    CapturedImages.forEach((image) => formData.append("image", image));
    if (voiceFile) {
      formData.append("voice", voiceFile);
    }

    try {
      const uploadResponse = await axios.post(
        `/auth/uploadFiles/${currentOrderId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (uploadResponse.status === 200) {
        toast.success("Files uploaded successfully");
        resetCaptureStates();
        setShowModal(false);
      } else {
        toast.error("Error uploading files");
      }
    } catch (error) {
      toast.error("Error uploading files");
    }
  };
  const navigateBasedOnRole = () => {
    if (auth?.role === "admin") {
      navigate("/order");
    } else if (auth?.role === "rider") {
      navigate("/rider/order");
    }
  };

  const resetCaptureStates = () => {
    setCapturedImages([]);
    setVoiceFile(null);
    setShowModal(false);
  };

  const sendWhatsAppTemplate = async (orderId) => {
    try {
      const { data: order } = await axiosPrivate.get(
        `auth/getOrderById/${orderId}`
      );

      const templatePayload = {
        template_name: "delivery_update_no_gst",
        broadcast_name: `delivery_update_no_gst_${orderId}`,
        parameters: [
          {
            name: "name",
            value: order.customerName,
          },
          {
            name: "total_bill",
            value: order.price,
          },
          {
            name: "service",
            value: order.service || "na",
          },
          {
            name: "invoice__payment_link",
            value: "na",
          },
        ],
      };

      const response = await instance.post(
        `/sendTemplateMessage?whatsappNumber=${order.contactNo}`,
        templatePayload
      );

      if (response.status === 200) {
        toast.success("WhatsApp message sent successfully!");
      } else {
        toast.error("Failed to send WhatsApp message.");
      }
    } catch (error) {
      console.log("Error sending WhatsApp message:", error);
      toast.error("Error sending WhatsApp message.");
    }
  };

  const sendWhatsAppTemplateDelivered = async (orderId) => {
    try {
      const { data: order } = await axiosPrivate.get(
        `auth/getOrderById/${orderId}`
      );

      const templatePayload = {
        template_name: "delivery_success",
        broadcast_name: `delivery_success_1725377117469`,
        parameters: [
          {
            name: "name",
            value: order.customerName,
          },
        ],
      };

      const response = await instance.post(
        `/sendTemplateMessage?whatsappNumber=${order.contactNo}`,
        templatePayload
      );

      if (response.status === 200) {
        toast.success("WhatsApp message sent successfully!");
      } else {
        toast.error("Failed to send WhatsApp message.");
      }
    } catch (error) {
      console.log("Error sending WhatsApp message:", error);
      toast.error("Error sending WhatsApp message.");
    }
  };

  // const handlePageClick = (selectedPage) => {
  //   setPageNumber(selectedPage.selected + 1);
  // };

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected + 1); // ReactPaginate uses zero-based index
  };

  // Modified to filter status options based on current status
  const getAvailableStatuses = (currentStatus) => {
    const statusFlow = {
      intransit: ["processing"],
      processing: ["ready for delivery"],
      "ready for delivery": ["delivered"],
    };
    return statusFlow[currentStatus] || [];
  };

  // show image and voice
  const handleOrderView = async (orderId) => {
    setSelectedOrder(orderId);
    setShowMultiMedia(true);

    try {
      const { data } = await axios.get(`/auth/getMedia/${orderId}`);
      const validPhotoUrls = (data.imageUrl || []).filter((url) => url);
      setPhotoUrls(validPhotoUrls);
      setVoiceUrl(data.voiceUrl || null);
    } catch (error) {
      console.error("Error fetching media:", error);
      setPhotoUrls([]);
      setVoiceUrl(null);
    }
  };

  // show deliver image
  const handleDeliveriesView = async (orderId) => {
    setSelectedOrder(orderId);
    setShowMedia(true);

    try {
      const { data } = await axios.get(`/auth/getMedia/${orderId}`);
      setPhotoUrl(data.deliverImage);
    } catch (error) {
      console.error("Error fetching media:", error);
    }
  };

  // const handleView = async (orderId) => {
  //   setSelectedOrder(orderId);
  //   setShowMedia(true);

  //   try {
  //     const { data } = await axios.get(`/auth/getMedia/${orderId}`);
  //     setPhotoUrl(data.intransitimg);
  //     setVoiceUrl(data.intransitvoi);
  //   } catch (error) {
  //     console.error("Error fetching media:", error);
  //   }
  // };

  const handleView = async (orderId) => {
    setSelectedOrder(orderId);
    setShowMultiMedia(true);

    try {
      const { data } = await axios.get(`/auth/getMedia/${orderId}`);
      // console.log("API Response:", data); // Log the full response

      // Validate and update photoUrls
      const validPhotoUrls = (data.intransitimg || []).filter((url) => url);
      // console.log("Valid Photo URLs:", validPhotoUrls);
      setPhotoUrls(validPhotoUrls);

      // Update voiceUrl
      setVoiceUrl(data.intransitvoi || null);
    } catch (error) {
      // console.error("Error fetching media:", error);
      setPhotoUrls([]);
      setVoiceUrl(null);
    }
  };

  const handleNull = () => {
    setShowMedia(false);
    setShowMultiMedia(false);
    setPhotoUrl(null);
    setVoiceUrl(null);
    setSelectedOrder(null);
    setPhotoUrls(null);
  };

  return (
    <>
      <Container className="p-3">
        <h1 className="text-center mb-4">Orders</h1>
        <Row>
          <Col>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Order No.</th>
                  <th>Name</th>
                  <th>Contact Number</th>
                  <th>Address</th>
                  <th>Pickup Time</th>
                  <th>plant</th>
                  <th>Total Bill</th>
                  <th>Update Status</th>
                  <th>Location</th>
                  <th>Pickup view</th>
                  <th>Delivery view</th>
                </tr>
              </thead>
              <tbody>
                {customer.map((user) => (
                  <tr key={user._id}>
                    <td>{user.order_id}</td>
                    <td>{user.customerName}</td>
                    <td>{user.contactNo}</td>
                    <td>{user.address}</td>
                    <td>
                      {moment(user.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
                    </td>
                    <td>{user.plantName}</td>
                    <td>₹{user.price}</td>
                    <td className="text-capitalize">
                      {user.isRescheduled ? (
                        <span>Scheduled</span> // Show 'Scheduled' if rescheduled is true
                      ) : (
                        <Select
                          bordered={false}
                          className={`custom-select ${
                            user.status === "delivered" ? "delivered" : ""
                          }`} // Add class based on status
                          popupClassName="custom-select-dropdown"
                          onChange={(value) => handleChange(user._id, value)}
                          defaultValue={user?.status}
                          disabled={user.status === "delivered"} // Disable dropdown for delivered status
                        >
                          {getAvailableStatuses(user.status).map((status) => (
                            <Option key={status} value={status}>
                              {status}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </td>

                    <td>
                      {user.orderLocation?.latitude &&
                      user.orderLocation?.longitude ? (
                        <a
                          href={`https://www.google.com/maps?q=${user.orderLocation.latitude},${user.orderLocation.longitude}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {/* tap here */}
                          <IoLocationSharp
                            className="mx-auto d-block"
                            style={{
                              fontSize: "40px",
                              cursor: "pointer",
                            }}
                          />
                        </a>
                      ) : (
                        "Location not available"
                      )}
                    </td>

                    <td>
                      <div
                        onMouseEnter={() => {
                          setHoveredButton(`${user._id}-pickup`);
                          setTooltipText("pickup view");
                        }}
                        onMouseLeave={() => {
                          setHoveredButton(null);
                          setTooltipText(""); // Clear tooltip text
                        }}
                        style={{ position: "relative" }}
                      >
                        <IoMdEye
                          onClick={() => handleView(user._id)}
                          className="mx-auto d-block"
                          style={{
                            fontSize: "25px",
                            color:
                              hoveredButton === `${user._id}-pickup`
                                ? "#043a3a"
                                : "teal",
                            cursor: "pointer",
                            transform:
                              hoveredButton === `${user._id}-pickup`
                                ? "scale(1.2)"
                                : "scale(1)",
                            transition: "all 0.2s ease-in-out",
                          }}
                        />

                        {hoveredButton === `${user._id}-pickup` && (
                          <div
                            style={{
                              position: "absolute",
                              top: "-25px",
                              left: "50%",
                              transform: "translateX(-50%)",
                              backgroundColor: "rgba(14, 177, 150, 0.8)",
                              color: "#fff",
                              padding: "5px 10px",
                              borderRadius: "5px",
                              fontSize: "12px",
                              whiteSpace: "nowrap",
                              zIndex: "10",
                            }}
                          >
                            {tooltipText}
                          </div>
                        )}
                      </div>
                    </td>
                    <td>
                      <div
                        onMouseEnter={() => {
                          setHoveredButton(`${user._id}-status`);
                          setTooltipText(
                            user.status === "delivered"
                              ? "Delivered"
                              : "Not Delivered"
                          );
                        }}
                        onMouseLeave={() => {
                          setHoveredButton(null);
                          setTooltipText(""); // Clear tooltip text
                        }}
                        style={{ position: "relative" }}
                      >
                        {user.status === "delivered" ? (
                          <IoMdEye
                            onClick={() => handleDeliveriesView(user._id)}
                            className="mx-auto d-block"
                            style={{
                              fontSize: "25px",
                              color:
                                hoveredButton === `${user._id}-status`
                                  ? "#043a3a"
                                  : "teal",
                              cursor: "pointer",
                              transform:
                                hoveredButton === `${user._id}-status`
                                  ? "scale(1.2)"
                                  : "scale(1)",
                              transition: "all 0.2s ease-in-out",
                            }}
                          />
                        ) : (
                          <IoMdEyeOff
                            onClick={() => handleDeliveriesView(user._id)}
                            className="mx-auto d-block"
                            style={{
                              fontSize: "25px",
                              color:
                                hoveredButton === `${user._id}-status`
                                  ? "#043a3a"
                                  : "rgba(76, 121, 114, 0.8)",
                              cursor: "pointer",
                              transform:
                                hoveredButton === `${user._id}-status`
                                  ? "scale(1.2)"
                                  : "scale(1)",
                              transition: "all 0.2s ease-in-out",
                            }}
                          />
                        )}

                        {hoveredButton === `${user._id}-status` && (
                          <div
                            style={{
                              position: "absolute",
                              top: "-25px",
                              left: "50%",
                              transform: "translateX(-50%)",
                              backgroundColor: "rgba(14, 177, 150, 0.8)",
                              color: "#fff",
                              padding: "5px 10px",
                              borderRadius: "5px",
                              fontSize: "12px",
                              whiteSpace: "nowrap",
                              zIndex: "10",
                            }}
                          >
                            {tooltipText}
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <div
          className="fixed-top right-100 p-3"
          style={{ zIndex: "6", left: "initial" }}
        >
          <BsFilterSquareFill
            style={{
              fontSize: "30px",
              cursor: "pointer",
              color: "teal",
              position: "fixed",
              top: "56px",
              right: "20px",
              zIndex: 10,
            }}
            onClick={() => setFilterShowCalendar(true)}
          />
        </div>
        {showFilterShowCalendar && (
          <div
            style={{
              position: "absolute",
              top: "100px",
              right: "20px",
              zIndex: 20,
            }}
          >
            <Calendar onChange={handleDateChange} value={selectedDate} />
          </div>
        )}
      </Container>
      {isLoading ? (
        <Loader loading={isLoading} />
      ) : (
        <Container fluid>
          <Row className="justify-content-center">
            <Col xs={12} sm={6}>
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </Col>
          </Row>
        </Container>
      )}

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Status Change</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to change the status of this order to "
          {newStatus}"?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload Files</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Webcamera onCapture={(image) => setCapturedImage(image)} />
            <Recorder onRecordingComplete={handleRecordingComplete} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpload}>
            Upload
          </Button>
        </Modal.Footer>
      </Modal> */}
      {/* Modal for Uploading Files */}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload Files</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {/* Webcamera to capture image */}
            <WebCamMulti onCapture={(image) => setCapturedImages(image)} />

            {/* Recorder to record voice */}
            <Recorder
              onRecordingComplete={(audioFile) => setVoiceFile(audioFile)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpload}>
            Upload
          </Button>
        </Modal.Footer>
      </Modal>

      {isLoading && <Loader />}

      {/* Modal to show photo and voice */}
      <Modal show={showMedia} onHide={handleNull}>
        <Modal.Header closeButton>
          <Modal.Title>Photo and Voice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {photoUrl ? (
            <div>
              <h3>Photo</h3>
              <img src={photoUrl} alt="Order" className="img-fluid" />
            </div>
          ) : (
            <p>No photo available.</p>
          )}
          {voiceUrl ? (
            <div>
              <h3>Voice Note</h3>
              <audio controls>
                <source src={voiceUrl} type="audio/wav" />
                Your browser does not support the audio element.
              </audio>
            </div>
          ) : (
            <p>No voice note available.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleNull}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal to show photo and voice */}
      <Modal show={showMultiMedia} onHide={handleNull}>
        <Modal.Header closeButton>
          <Modal.Title>Photo and Voice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {photoUrls && photoUrls.length > 0 ? (
            <div>
              <h3>Photos</h3>
              <Carousel>
                {photoUrls.map((url, index) => {
                  // console.log("Rendering photo URL:", url); // Debugging log
                  return (
                    <Carousel.Item key={index}>
                      <img
                        src={url}
                        alt={`Order Photo ${index + 1}`}
                        className="d-block w-100 img-fluid"
                        style={{ maxHeight: "500px", objectFit: "contain" }}
                      />
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </div>
          ) : (
            <p>No photos available.</p>
          )}
          {voiceUrl ? (
            <div>
              <h3>Voice Note</h3>
              <audio controls>
                <source src={voiceUrl} type="audio/wav" />
                Your browser does not support the audio element.
              </audio>
            </div>
          ) : (
            <p>No voice note available.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleNull}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomerDetails;
