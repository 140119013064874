// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-dashboard {
  display: flex;
  flex-direction: row;
  gap: 40px;
  width: 100%;
  margin-left: 20px;
}
.card-dashboard {
  width: 20rem;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  background: rgba(0, 128, 128, 0.164);
  color: teal;
  border: 1px solid teal;
  cursor: pointer;
  padding: 20px;
  border-radius: 8px;
}

.card-dashboard:hover {
  box-shadow: none;
  outline: none;
  border: 1px solid teal;
}

.card-text-dashboard {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 10px;
  font-weight: 700;
}

/* .card-text > :first-child {
  color: red;
}
.card-text > :last-child {
  color: teal;
} */

/* Mobile View*/
@media only screen and (min-width: 300px) and (max-width: 500px) and (min-height: 500px) and (max-height: 1200px) {
  .container-dashboard {
    display: flex;
    flex-direction: column;
  }

  .card {
    width: 25rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Home.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,WAAW;EACX,iBAAiB;AACnB;AACA;EACE,YAAY;EACZ,8EAA8E;EAC9E,oCAAoC;EACpC,WAAW;EACX,sBAAsB;EACtB,eAAe;EACf,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,sBAAsB;EACtB,SAAS;EACT,gBAAgB;AAClB;;AAEA;;;;;GAKG;;AAEH,eAAe;AACf;EACE;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,YAAY;EACd;AACF","sourcesContent":[".container-dashboard {\r\n  display: flex;\r\n  flex-direction: row;\r\n  gap: 40px;\r\n  width: 100%;\r\n  margin-left: 20px;\r\n}\r\n.card-dashboard {\r\n  width: 20rem;\r\n  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;\r\n  background: rgba(0, 128, 128, 0.164);\r\n  color: teal;\r\n  border: 1px solid teal;\r\n  cursor: pointer;\r\n  padding: 20px;\r\n  border-radius: 8px;\r\n}\r\n\r\n.card-dashboard:hover {\r\n  box-shadow: none;\r\n  outline: none;\r\n  border: 1px solid teal;\r\n}\r\n\r\n.card-text-dashboard {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: start;\r\n  justify-content: start;\r\n  gap: 10px;\r\n  font-weight: 700;\r\n}\r\n\r\n/* .card-text > :first-child {\r\n  color: red;\r\n}\r\n.card-text > :last-child {\r\n  color: teal;\r\n} */\r\n\r\n/* Mobile View*/\r\n@media only screen and (min-width: 300px) and (max-width: 500px) and (min-height: 500px) and (max-height: 1200px) {\r\n  .container-dashboard {\r\n    display: flex;\r\n    flex-direction: column;\r\n  }\r\n\r\n  .card {\r\n    width: 25rem;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
