import { Outlet } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar/Sidebar";
import "./Layout.css";
import { useState } from "react";

const Layout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <>
      <div className="layout">
        <div className="navbar_2">
          <Header toggleSidebar={toggleSidebar} />
        </div>
        <div className="main-content">
          <Sidebar isOpen={isSidebarOpen} />

          <main className="content">
            <Outlet />
          </main>
        </div>
      </div>
    </>
  );
};

export default Layout;
