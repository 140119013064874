// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile_menu_icon {
  display: none;
}

@media only screen and (min-width: 300px) and (max-width: 500px) and (min-height: 500px) and (max-height: 1200px) {
  .mobile_menu_icon {
    display: block;
  }

  .navbar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px 10px 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Header.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE;IACE,cAAc;EAChB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,2BAA2B;EAC7B;AACF","sourcesContent":[".mobile_menu_icon {\r\n  display: none;\r\n}\r\n\r\n@media only screen and (min-width: 300px) and (max-width: 500px) and (min-height: 500px) and (max-height: 1200px) {\r\n  .mobile_menu_icon {\r\n    display: block;\r\n  }\r\n\r\n  .navbar {\r\n    width: 100%;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: space-between;\r\n    padding: 10px 0px 10px 10px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
