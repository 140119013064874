import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Table,
  Container,
  Row,
  Col,
  Modal,
  Button,
  Carousel,
} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import "../../style/responsive.css";
import { BsFilterSquareFill } from "react-icons/bs";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import axios from "../../config";
import { HiEye } from "react-icons/hi";
import Loader from "./../CustomLoader";
import useAxiosPrivate from "./../../hooks/useAxiosPrivate";
import useAuth from "./../../hooks/useAuth";
import { IoMdEyeOff } from "react-icons/io";

const RiderDocuments = () => {
  const [customer, setCustomer] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [showFilterShowCalendar, setFilterShowCalendar] = useState(false);
  const [showMultiMedia, setShowMultiMedia] = useState(false);
  const [photoUrls, setPhotoUrls] = useState([]);
  const [voiceUrl, setVoiceUrl] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const { auth } = useAuth();
  const pageSize = 15;
  const axiosPrivate = useAxiosPrivate();

  const getCustomer = async () => {
    try {
      const userEmail = auth?.email;
      // Add status to query string to fetch "processing" orders
      const customer = await axiosPrivate.get(
        `/getOrdersByFilter?limit=${pageSize}&page=${pageNumber}&email=${userEmail}&status=ready for delivery`
      );

      setPageCount(Math.ceil(customer?.data?.total / pageSize));
      setCustomer(customer.data.orders);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getCustomer();
  }, [pageNumber]);

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected + 1); // ReactPaginate uses zero-based index
  };

  const handleOrderView = async (orderId) => {
    setSelectedOrder(orderId);
    setShowMultiMedia(true);

    try {
      const { data } = await axios.get(`/auth/getMedia/${orderId}`);
      const validPhotoUrls = (data.imageUrl || []).filter((url) => url);
      setPhotoUrls(validPhotoUrls);
      setVoiceUrl(data.voiceUrl || null);
    } catch (error) {
      console.error("Error fetching media:", error);
      setPhotoUrls([]);
      setVoiceUrl(null);
    }
  };

  const handleNull = () => {
    setShowMultiMedia(false);
    setPhotoUrls([]);
    setVoiceUrl(null);
  };
  return (
    <>
      <Container className="p-3">
        <h1 className="text-center mb-4">Documents</h1>
        <Row>
          <Col>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Order No.</th>
                  <th>Name</th>
                  <th>Contact Number</th>
                  <th>Address</th>
                  <th>Total Bill</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {customer.map((user) => (
                  <tr key={user._id}>
                    <td>{user.order_id}</td>
                    <td>{user.customerName}</td>
                    <td>{user.contactNo}</td>
                    <td>{user.address}</td>
                    <td>₹{user.price}</td>
                    <td className="text-capitalize">{user.status}</td>
                    <td>
                      {user.image && user.image.length > 0 ? (
                        <HiEye
                          className="mx-auto d-block"
                          style={{
                            fontSize: "25px",
                            color: "teal",
                            cursor: "pointer",
                          }}
                          onClick={() => handleOrderView(user._id)}
                        />
                      ) : (
                        <>
                          <IoMdEyeOff
                            className="mx-auto d-block"
                            style={{
                              fontSize: "25px",
                              color: "rgba(76, 121, 114, 0.8)",
                              cursor: "pointer",
                            }}
                          />
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>

      <Modal show={showMultiMedia} onHide={handleNull}>
        <Modal.Header closeButton>
          <Modal.Title>Photo and Voice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {photoUrls && photoUrls.length > 0 ? (
            <div>
              <h3>Photos</h3>
              <Carousel>
                {photoUrls.map((url, index) => (
                  <Carousel.Item key={index}>
                    <img
                      src={url}
                      alt={`Order Photo ${index + 1}`}
                      className="d-block w-100 img-fluid"
                      style={{ maxHeight: "500px", objectFit: "contain" }}
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          ) : (
            <p>No photos available.</p>
          )}
          {voiceUrl ? (
            <div>
              <h3>Voice Note</h3>
              <audio controls>
                <source src={voiceUrl} type="audio/wav" />
                Your browser does not support the audio element.
              </audio>
            </div>
          ) : (
            <p>No voice note available.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleNull}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {isLoading ? (
        <Loader loading={isLoading} />
      ) : (
        <Container fluid>
          <Row className="justify-content-center">
            <Col xs={12} sm={6}>
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default RiderDocuments;
