// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout {
  display: flex;
  height: 100vh;
  flex-direction: column;
}

.navbar_2 {
  /* height: 60px; */
  width: 100%;
}

.main-content {
  display: flex;
  flex-grow: 1;
}

.content {
  flex-grow: 1;
  /* padding: 0px 30px 0px 40px; */
  overflow-y: auto;
}

/* Mobile View */
@media only screen and (min-width: 300px) and (max-width: 500px) and (min-height: 500px) and (max-height: 1200px) {
  .content {
    flex-grow: 1;
    padding: 0px 20px 0px 20px;
    overflow: hidden;
  }

  .main-content {
    display: flex;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Layout.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,gCAAgC;EAChC,gBAAgB;AAClB;;AAEA,gBAAgB;AAChB;EACE;IACE,YAAY;IACZ,0BAA0B;IAC1B,gBAAgB;EAClB;;EAEA;IACE,aAAa;EACf;AACF","sourcesContent":[".layout {\r\n  display: flex;\r\n  height: 100vh;\r\n  flex-direction: column;\r\n}\r\n\r\n.navbar_2 {\r\n  /* height: 60px; */\r\n  width: 100%;\r\n}\r\n\r\n.main-content {\r\n  display: flex;\r\n  flex-grow: 1;\r\n}\r\n\r\n.content {\r\n  flex-grow: 1;\r\n  /* padding: 0px 30px 0px 40px; */\r\n  overflow-y: auto;\r\n}\r\n\r\n/* Mobile View */\r\n@media only screen and (min-width: 300px) and (max-width: 500px) and (min-height: 500px) and (max-height: 1200px) {\r\n  .content {\r\n    flex-grow: 1;\r\n    padding: 0px 20px 0px 20px;\r\n    overflow: hidden;\r\n  }\r\n\r\n  .main-content {\r\n    display: flex;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
